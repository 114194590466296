@import "../../styles/uitilities.scss";
.root{
    max-width: 800px;
    margin: auto;
    border: 1px solid #dddddd;
    border-radius: 20px;
    padding: 30px 20px;
    text-align: center;
    @include max(767.98){
        padding: 20px 16px;
    }
    .title{
        margin-bottom: 20px;
        @include max(767.98) {
            margin-bottom: 12px;
        }
    }
    .subtitle{
        text-transform: initial;
        margin-bottom: 0;
    }
    .img_wrap{
        max-width: 500px;
        margin: 30px auto 0;
        @include max(767.98){
            margin: 20px auto 0;
        }
        figure{
            position: relative;
            padding-bottom: 65%;
            width: 100%;
            overflow: hidden;
            border-radius: 5px;
            img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
              -o-object-fit: cover;
              top: 0;
              left: 0;
            }
        }
    }
    .btn{
        min-width: 160px;
        @include max(575.98){
            width: 100%;
        }
    }
    .footer{
        >*{
            margin: 0;
        }
    }
    .order_details {
        display: flex;
        justify-content: center;
        width: 100%;

        .items {
            width: 100%;
        }
    }
    .summary_wrapper {
        display: flex;
        max-width: 500px;
        justify-content: center;
        margin: auto;
        text-align: left;
        @include max(576){
            flex-direction: column;
            text-align: center;
        }
    }
}